import IHeading from "@/components/IHeading"
import ILine from "@/components/ILine"
import IParagraph from "@/components/IParagraph"
import Layout from "@/components/Layout"
import PageHeading from "@/components/PageHeading"
import PageIntro from "@/components/PageIntro"
import Seo from "@/components/seo"
import * as React from "react"
import DesignIcon from "../../images/our-process/Design.svg"
import GrowthIcon from "../../images/our-process/Growth.svg"
import StrategyIcon from "../../images/our-process/Strategy.svg"
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined"
import GroupWorkOutlinedIcon from "@material-ui/icons/GroupWorkOutlined"
import AspectRatioOutlinedIcon from "@material-ui/icons/AspectRatioOutlined"
import ImportantDevicesOutlinedIcon from "@material-ui/icons/ImportantDevicesOutlined"
import TrendingUp from "@material-ui/icons/TrendingUp"
import Whatshot from "@material-ui/icons/Whatshot"
import Group from "@material-ui/icons/Group"
import Timeline from "@material-ui/icons/Timeline"
import FAQs from "@/components/FAQs"
import CallToAction from "@/components/CallToAction"
import { graphql, PageProps } from "gatsby"


interface WoocommerceWordpressPageData {
  mdx: {
    body: string
    excerpt: string
    frontmatter: {
      title: string
      description: string
      iconName: string
      color?: string
    }
  }
}
const WoocommerceWordpressPage : React.FC<PageProps<WoocommerceWordpressPageData>> = ({ data }) => {
  const { frontmatter, excerpt } = data.mdx
  const { title, description } = frontmatter
  const feature = [
    {
      title: "Seamless integration with WordPress",
    },
    {
      title: "Extensive customization options",
    },
    {
      title: "Large library of themes and plugins",
    },
    {
      title: "Built-in payment processing options",
    },
    {
      title: "Inventory and tax management",
    },
    {
      title: "SEO-friendly structure",
    },
    {
      title: "Scalable from small businesses to large enterprises",
    },
  ]
  const experties = [
    {
      title: "Years of experience",
      content:
        " 9+ years of WordPress development. 6+ years of focused WooCommerce implementation. 150+ successful WooCommerce projects delivered",
      imgSrc: DesignIcon,
    },
    {
      title: "Notable projects",
      content:
        "Developed a custom WooCommerce solution for a multi-vendor marketplace, resulting in a 75% increase in vendor sign-ups. Created a subscription-based WooCommerce store for a gourmet food delivery service, leading to a 50% growth in recurring revenue",
      imgSrc: GrowthIcon,
    },
    {
      title: "Certifications and partnerships",
      content:
        "WordPress VIP Partner. WooExpert status. Multiple team members with WordPress Developer certifications",
      imgSrc: StrategyIcon,
    },
  ]
  const items = [
    {
      imgSrc: <ImportantDevicesOutlinedIcon />,
      title: "Development",
      desc: " Custom WooCommerce theme development. Plugin development and customization. Payment gateway integrations. Multi-vendor marketplace setups",
    },
    {
      imgSrc: <AspectRatioOutlinedIcon />,
      title: "Migration",
      desc: "Seamless migration from other platforms to WooCommerce. WordPress and WooCommerce version upgrades. Data migration and integrity checks",
    },
    {
      imgSrc: <GroupOutlinedIcon />,
      title: " Support and maintenance",
      desc: "Ongoing WordPress and WooCommerce management. Regular updates and security patches. Backup and recovery solutions.",
    },
    {
      imgSrc: <GroupWorkOutlinedIcon />,
      title: " Integration",
      desc: " Third-party service integrations (CRM, ERP, shipping providers). Custom API development. Social media and marketing tool integrations.",
    },
    {
      imgSrc: <TrendingUp />,
      title: "Optimization",
      desc: "Performance optimization for high-traffic stores. Conversion rate optimization (CRO). Search engine optimization (SEO).",
    },
  ]
  const helpItems = [
    {
      icon: <Whatshot />,
      title: "Unique selling points",
      content:
        "Deep understanding of both WordPress and WooCommerce ecosystems. Expertise in creating scalable and high-performance e-commerce solutions. Focus on delivering customized solutions that align with business goals",
    },
    {
      icon: <Group />,
      title: "Team expertise",
      content:
        " Skilled WordPress and WooCommerce developers. Experienced UX/UI designers specialized in e-commerce. Dedicated project managers and e-commerce strategists.",
    },
    {
      icon: <Timeline />,
      title: " Process highlights",
      content:
        "Comprehensive discovery and planning phase. Agile development methodology with regular client feedback. Rigorous testing across devices and browsers. Smooth deployment and post-launch support",
    },
  ]
  const faqData = [
    {
      question: "How does WooCommerce compare to other e-commerce platforms?",
      answer:
        "WooCommerce offers unparalleled flexibility and customization options, especially when combined with WordPress. It's particularly advantageous for businesses that want full control over their online store's functionality and appearance, often at a lower total cost of ownership compared to other platforms.",
    },
    {
      question: " Can WooCommerce handle a large number of products?",
      answer:
        "Yes, WooCommerce can efficiently manage stores with thousands of products. With proper optimization and potentially some custom development, it can scale to handle extensive product catalogs and high traffic volumes.",
    },
    {
      question: " Is WooCommerce secure for processing payments?",
      answer:
        "WooCommerce takes security seriously and is designed to be secure out of the box. It supports various secure payment gateways and complies with PCI standards. Additionally, we implement best practices for security, including regular updates and robust hosting solutions, to ensure your store remains secure.",
    },
    {
      question: "Can you help with SEO for my WooCommerce store?",
      answer:
        "Absolutely. We offer comprehensive SEO services tailored for WooCommerce stores. This includes optimizing product pages, implementing schema markup, improving site speed, and setting up SEO-friendly URL structures. WordPress and WooCommerce provide a great foundation for SEO, which we further enhance with our expertise.",
    },
    {
      question:
        "What kind of ongoing support do you provide for WooCommerce stores?",
      answer:
        "We offer comprehensive support packages that include regular maintenance, security updates, performance monitoring, and ongoing optimizations. We can also provide training for your team, assist with content updates, and offer strategic advice to help grow your online store.",
    },
  ]
  return (
    <>
      <Layout>
        <Seo
          title="WooCommerce & WordPress E-commerce Experts"
          description="Create powerful, customized online stores with our WooCommerce and WordPress expertise. 9+ years of development experience."
        />
        <div data-aos="fade-up">
          <PageHeading
            title={title}
            description={description}
          />
        </div>
        <div data-aos="fade-up">
          <PageIntro>
            {excerpt}
          </PageIntro>
        </div>
        <section className="w-full my-16">
          <div className="container mx-auto">
            <div className="w-full">
              <div>
                <div data-aos="fade-up">
                  <IHeading align="left">Platform Advantages</IHeading>
                </div>
                <div className="xl:w-2/4 lg:w-3/4" data-aos="fade-up">
                  <IParagraph>
                    WooCommerce is ideal for businesses of all sizes, from small
                    startups to large corporations, particularly those looking
                    for a highly customizable and cost-effective e-commerce
                    solution. It's suitable for various industries, including
                    retail, digital products, subscriptions, and services.
                  </IParagraph>
                </div>
              </div>
              <div className="">
                <ILine align="left" />
              </div>
              <div className="grid grid-cols-1 py-10 -mx-1 overflow-hidden md:grid-cols-2 gap-x-10">
                {feature.map((item: any, index: number) => {
                  return (
                    <div
                      className="relative w-full px-6 my-6 overflow-hidden group"
                      key={index}
                      data-aos="fade-up"
                    >
                      <IHeading size="three">{item.title}</IHeading>
                      <IParagraph>{item.content}</IParagraph>
                      <div
                        className="absolute top-0 bottom-0 left-0 w-3 duration-300 bg-primary-200 dark:bg-primary-700 group-hover:w-full"
                        style={{ zIndex: "-1" }}
                      ></div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
        <section className="w-full pt-12 pb-24 my-20 bg-gray-800 lg:my-36 md:pt-24 dark:bg-primary-900">
          <div className="container mx-auto">
            <div data-aos="fade-up">
              <IHeading align="text-center" color="white">
                Our Expertise
              </IHeading>
            </div>
            <div data-aos="fade-up">
              <IParagraph align="center" color="white">
                Our team brings years of specialized experience in WooCommerce
                and WordPress development, helping businesses create unique and
                powerful online stores tailored to their specific needs.
              </IParagraph>
            </div>
            <div className="grid grid-cols-1 mt-20 md:grid-cols-2 lg:grid-cols-3 gap-x-10 xl:gap-x-20 gap-y-20">
              {experties.map((item: any, index: number) => {
                return (
                  <div
                    className="relative p-6 pt-10 duration-300 border-4 border-gray-600 group md:p-8 lg:pt-16 hover:border-white"
                    key={index}
                    data-aos="fade-up"
                  >
                    <div className="absolute px-4 bg-gray-800 help-icon-box dark:bg-primary-900 -top-10">
                      <img src={item.imgSrc} alt="dddd" />
                    </div>
                    <IHeading size="subHeader" color="white">
                      {item.title}
                    </IHeading>
                    <IParagraph color="gray">
                      <ul className="py-4 space-y-3 list-disc">
                        {item.content
                          .split(".")
                          .filter((sentence: string) => sentence.trim() !== "") // Filter out any empty strings
                          .map(
                            (
                              sentence: string,
                              index: React.Key | null | undefined
                            ) => (
                              <li key={index}>{sentence.trim()}.</li>
                            )
                          )}
                      </ul>
                    </IParagraph>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <section className="w-full my-20">
          <div className="container mx-auto">
            <div data-aos="fade-up">
              <IHeading align="text-center">Services Offered</IHeading>
            </div>
            <div className="grid grid-cols-1 mb-12 lg:grid-cols-2 gap-x-10 gap-y-28 mt-36">
              {items.map((item: any) => {
                return (
                  <div
                    className="flex flex-col items-center p-5 rounded-lg shadow-xl bg-slate-700"
                    data-aos="fade-up"
                  >
                    <div className="flex items-center justify-center mb-6 -mt-20 overflow-hidden rounded-full icon-box w-28 md:w-40 h-28 md:h-40 bg-slate-200">
                      {item.imgSrc}
                    </div>
                    <IHeading size="subHeader" color="white">
                      {item.title}
                    </IHeading>
                    <div className="mt-3 mb-6">
                      <ILine />
                    </div>
                    <IParagraph color="gray">
                      <ul className="px-16 space-y-3 list-disc">
                        {item.desc
                          .split(".")
                          .filter((sentence: string) => sentence.trim() !== "") // Filter out any empty strings
                          .map(
                            (
                              sentence: string,
                              index: React.Key | null | undefined
                            ) => (
                              <li key={index}>{sentence.trim()}.</li>
                            )
                          )}
                      </ul>
                    </IParagraph>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <section className="w-full my-28">
          <div className="container mx-auto">
            <div data-aos="fade-up">
              <IHeading align="text-center">Why Choose Us for Shopify</IHeading>
            </div>
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 mt-14">
              {helpItems.map((item: any, index: number) => {
                return (
                  <div
                    className="relative flex flex-col items-center p-10 overflow-hidden border-b-2 rounded-md shadow-xl group border-slate-600 dark:border-primary-400 dark:bg-primary-900"
                    key={index}
                    data-aos="fade-up"
                  >
                    <div className="z-10 mb-6 startup-help-icon-box">
                      {item.icon}
                    </div>
                    <IHeading size="subHeader" align="text-center z-10">
                      {item.title}
                    </IHeading>
                    <p className="absolute top-0 bottom-0 left-0 right-0 z-0 p-8 text-gray-200 duration-300 opacity-0 bg-slate-600 dark:bg-primary-600 group-hover:opacity-100 group-hover:z-20">
                      <ul className="space-y-3 list-disc ">
                        {item.content
                          .split(".")
                          .filter((sentence: string) => sentence.trim() !== "") // Filter out any empty strings
                          .map(
                            (
                              sentence: string,
                              index: React.Key | null | undefined
                            ) => (
                              <li key={index}>{sentence.trim()}.</li>
                            )
                          )}
                      </ul>
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <FAQs faqs={faqData} />
        <CallToAction
          title="Unleash Your E-commerce Potential with WooCommerce"
          description="Ready to create a powerful, flexible online store that truly represents your brand? Our WooCommerce experts are here to bring your vision to life and drive your e-commerce success"
          cta="Start Your WooCommerce Journey"
          ctaTo="/contact-us"
        />
      </Layout>
    </>
  )
}


export const query = graphql`
  query WoocommerceWordpressPageQuery {
    mdx(
      fileAbsolutePath: { regex: "/(technologies)/" }
      slug: { regex: "/(technologies/woocommerce-wordpress)/" }
    ) {
      excerpt(pruneLength: 1000)
      frontmatter {
        title
        iconName
        description
      }
    }
  }
`
export default WoocommerceWordpressPage
